/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';

import { IconProviderNetent } from 'components/icons/icons-provider/icon-provider-netent';
import { IconProviderYggdrasil } from 'components/icons/icons-provider/icon-provider-yggdrasil';
import { IconProvider1x2Gaming } from 'components/icons/icons-provider/icon-provider-1x2gaming';
import { IconProviderBetsoft } from 'components/icons/icons-provider/icon-provider-betsoft';
import { IconProviderBoomingGames } from 'components/icons/icons-provider/icon-provider-booming-games';
import { IconProviderBooongo } from 'components/icons/icons-provider/icon-provider-booongo';
import { IconProviderAmusnetEgt } from 'components/icons/icons-provider/icon-provider-amusnet-egt';
import { IconProviderEvolution } from 'components/icons/icons-provider/icon-provider-evolution';
import { IconProviderEzugi } from 'components/icons/icons-provider/icon-provider-ezugi';
import { IconProviderGameArt } from 'components/icons/icons-provider/icon-provider-gameart';
import { IconProviderHabanero } from 'components/icons/icons-provider/icon-provider-habanero';
import { IconProviderISoftbet } from 'components/icons/icons-provider/icon-provider-isoftbet';
import { IconProviderMicroGaming } from 'components/icons/icons-provider/icon-provider-microgaming';
import { IconProviderPlayngo } from 'components/icons/icons-provider/icon-provider-playngo';
import { IconProviderPlayson } from 'components/icons/icons-provider/icon-provider-playson';
import { IconProviderPragmatic } from 'components/icons/icons-provider/icon-provider-pragmatic';
import { IconProviderQuickSpin } from 'components/icons/icons-provider/icon-provider-quickspin';
import { IconProviderRedrake } from 'components/icons/icons-provider/icon-provider-redrake';
import { IconProviderTomHornGaming } from 'components/icons/icons-provider/icon-provider-tom-horn-gaming';
import { IconProviderElk } from 'components/icons/icons-provider/icon-provider-elk';
import { IconProviderRelax } from 'components/icons/icons-provider/icon-provider-relax';
import { IconProviderNovomatic } from 'components/icons/icons-provider/icon-provider-novomatic';
import { IconProviderHacksaw } from 'components/icons/icons-provider/icon-provider-hacksaw';
import { IconProviderNolimit } from 'components/icons/icons-provider/icon-provider-nolimit';
import { IconProviderPushgaming } from 'components/icons/icons-provider/icon-provider-pushgaming';
import { IconProviderPragmaticPlay } from 'components/icons/icons-provider/icon-provider-pragmatic-play';
import { IconProviderGamomat } from 'components/icons/icons-provider/icon-provider-gamomat-small';
import { IconProviderGivme } from 'components/icons/icons-provider/icon-provider-givme-small';
import { IconProviderKalambaBig } from 'components/icons/icons-provider/icon-provider-kalamba';
import { IconProviderKalamba } from 'components/icons/icons-provider/icon-provider-kalamba-small';
import { IconProviderOryx } from 'components/icons/icons-provider/icon-provider-oryx-small';
import { IconProviderRelaxGaming } from 'components/icons/icons-provider/icon-provider-relax-gaming';
import { IconProviderEvoplay } from 'components/icons/icons-provider/icon-provider-evoplay';
import { IconProviderPlankGaming } from 'components/icons/icons-provider/icon-provider-plank-gaming';
import { IconProviderSpribe } from 'components/icons/icons-provider/icon-provider-spribe';
import { IconProviderTvbet } from 'components/icons/icons-provider/icon-provider-tvbet';
import { IconProviderBigtimeGaming } from 'components/icons/icons-provider/icon-provider-bigtime-gaming';
import { IconProviderSpribeBig } from 'components/icons/icons-provider/icon-provider-spribe-big';
import { IconProviderTvbetBig } from 'components/icons/icons-provider/icon-provider-tvbet-big';
import { IconProviderQuickSpinBig } from 'components/icons/icons-provider/icon-provider-quickspin-big';
import { IconProviderTomHornGamingBig } from 'components/icons/icons-provider/icon-provider-tom-horn-gaming-big';
import { IconProviderNolimitBig } from 'components/icons/icons-provider/icon-provider-nolimit-big';
import { IconProviderPushgamingBig } from 'components/icons/icons-provider/icon-provider-pushgaming-big';
import { IconProviderHacksawBig } from 'components/icons/icons-provider/icon-provider-hacksaw-big';
import { IconProviderPragmaticLiveBig } from 'components/icons/icons-provider/icon-provider-pragmatic-live-big';
import { IconProviderEvoplayBig } from 'components/icons/icons-provider/icon-provider-evoplay-big';
import { IconProviderGamomatBig } from 'components/icons/icons-provider/icon-provider-gamomat-big';
import { IconProviderPlankGamingBig } from 'components/icons/icons-provider/icon-provider-plank-gaming-big';
import { IconProviderBigtimeGamingBig } from 'components/icons/icons-provider/icon-provider-bigtime-gaming-big';
import { IconProviderGivmeBig } from 'components/icons/icons-provider/icon-provider-givme';
import { IconProviderWazdan } from 'components/icons/icons-provider/icon-provider-wazdan';
import { IconProviderWazdanBig } from 'components/icons/icons-provider/icon-provider-wazdan-big';
import { IconProviderRelaxGamingBig } from 'components/icons/icons-provider/icon-provider-relax-gaming-big';
import { IconProvider1x2gamingBig } from 'components/icons/icons-provider/icon-provider-1x2gaming-big';
import { IconProviderBetsoftBig } from 'components/icons/icons-provider/icon-provider-betsoft-big';
import { IconProviderBoomingGamesBig } from 'components/icons/icons-provider/icon-provider-booming-games-big';
import { IconProviderBooongoBig } from 'components/icons/icons-provider/icon-provider-booongo-big';
import { IconProviderAmusnetEgtBig } from 'components/icons/icons-provider/icon-provider-amusnet-egt-big';
import { IconProviderEvolutionBig } from 'components/icons/icons-provider/icon-provider-evolution-big';
import { IconProviderEzugiBig } from 'components/icons/icons-provider/icon-provider-ezugi-big';
import { IconProviderGameArtBig } from 'components/icons/icons-provider/icon-provider-gameart-big';
import { IconProviderHabaneroBig } from 'components/icons/icons-provider/icon-provider-habanero-big';
import { IconProviderISoftbetBig } from 'components/icons/icons-provider/icon-provider-isoftbet-big';
import { IconProviderMicrogamingBig } from 'components/icons/icons-provider/icon-provider-microgaming-big';
import { IconProviderPlaysonBig } from 'components/icons/icons-provider/icon-provider-playson-big';
import { IconProviderNetentBig } from 'components/icons/icons-provider/icon-provider-netent-big';
import { IconProviderPragmaticBig } from 'components/icons/icons-provider/icon-provider-pragmatic-big';
import { IconProviderRedrakeBig } from 'components/icons/icons-provider/icon-provider-redrake-big';
import { IconProviderYggdrasilBig } from 'components/icons/icons-provider/icon-provider-yggdrasil-big';
import { IconProviderElkBig } from 'components/icons/icons-provider/icon-provider-elk-big';
import { IconProviderNovomaticBig } from 'components/icons/icons-provider/icon-provider-novomatic-big';
import { IconProviderOryxBig } from 'components/icons/icons-provider/icon-provider-oryx';
import { IconProviderGGames } from 'components/icons/icons-provider/icon-provider-g-games';
import { IconProviderPlaytech } from 'components/icons/icons-provider/icon-provider-playtech';
import { IconProviderPlaytechBig } from 'components/icons/icons-provider/icon-provider-playtech-big';
import { IconProviderBGAMINGBig } from 'components/icons/icons-provider/icon-provider-bgamingBig';
import { IconProviderBGAMING } from 'components/icons/icons-provider/icon-provider-bgaming';
import { IconProviderCandleBets } from 'components/icons/icons-provider/icon-provider-candle-bets';
import { IconProviderCandleBetsBig } from 'components/icons/icons-provider/icon-provider-candle-bets-big';
import { IconProviderReevo } from 'components/icons/icons-provider/icon-provider-reevo';
import { IconProviderReevoBig } from 'components/icons/icons-provider/icon-provider-reevo-big';
import { IconProviderPlaytechLive } from 'components/icons/icons-provider/icon-provider-playtech-live';
import { IconProviderPlaytechLiveBig } from 'components/icons/icons-provider/icon-provider-playtech-live-big';
import { IconProviderRedSeven } from 'components/icons/icons-provider/icon-provider-red-seven';
import { IconProviderRedSevenBig } from 'components/icons/icons-provider/icon-provider-red-seven-big';
import { IconProviderRedTigerBig } from 'components/icons/icons-provider/icon-provider-red-tiger-big';
import { IconProviderRedTiger } from 'components/icons/icons-provider/icon-provider-red-tiger';
import { IconProviderSwintt } from 'components/icons/icons-provider/icon-provider-swintt';
import { IconProviderSwinttBig } from 'components/icons/icons-provider/icon-provider-swintt-big';
import { IconProvider3oaksGaming } from 'components/icons/icons-provider/icon-provider-3oaks-gaming';
import { IconProvider3oaksGamingBig } from 'components/icons/icons-provider/icon-provider-3oaks-gaming-big';
import { IconProviderFugasoBig } from 'components/icons/icons-provider/icon-provider-fugaso-big';
import { IconProviderFugaso } from 'components/icons/icons-provider/icon-provider-fugaso';
import { IconProviderAviatrix } from 'components/icons/icons-provider/icon-provider-aviatrix';
import { IconProviderAviatrixBig } from 'components/icons/icons-provider/icon-provider-aviatrix-big';
import { IconProviderApollo } from 'components/icons/icons-provider/icon-provider-apollo';
import { IconProviderApolloBig } from 'components/icons/icons-provider/icon-provider-apollo-big';
import { IconProviderEgt } from 'components/icons/icons-provider/icon-provider-egt';
import { IconProviderEgtBig } from 'components/icons/icons-provider/icon-provider-egt-big';
import { IconProviderFazi } from 'components/icons/icons-provider/icon-provider-fazi';
import { IconProviderGamzix } from 'components/icons/icons-provider/icon-provider-gamzix';
import { IconProviderGamzixBig } from 'components/icons/icons-provider/icon-provider-gamzix-big';
import { IconProviderGTStudios } from 'components/icons/icons-provider/icon-provider-gtstudios';
import { IconProviderElysium } from 'components/icons/icons-provider/icon-provider-elysium';
import { IconProviderElysiumBig } from 'components/icons/icons-provider/icon-provider-elysium-big';
import { IconProviderMascot } from 'components/icons/icons-provider/icon-provider-mascot';
import { IconProviderMascotBig } from 'components/icons/icons-provider/icon-provider-mascot-big';
import { IconProviderOnlyPlay } from 'components/icons/icons-provider/icon-provider-only-play';
import { IconProviderOnlyPlayBig } from 'components/icons/icons-provider/icon-provider-only-play-big';
import { IconProviderThunderkickBig } from 'components/icons/icons-provider/icon-provider-thunderkick-big';
import { IconProviderThunderkick } from 'components/icons/icons-provider/icon-provider-thunderkick';
import { IconProviderTurboGames } from 'components/icons/icons-provider/icon-provider-turbogames';
import { IconProviderTurboGamesBig } from 'components/icons/icons-provider/icon-provider-turbogames-big';
import { IconProviderAmigoGaming } from 'components/icons/icons-provider/icon-provider-amigo-gaming';
import { IconProviderAmigoGamingBig } from 'components/icons/icons-provider/icon-provider-amigo-gaming-big';
import { IconProviderHigh5 } from 'components/icons/icons-provider/icon-provider-high5';
import { IconProviderSlotopia } from 'components/icons/icons-provider/icon-provider-slotopia';
import { IconProviderPlatipus } from 'components/icons/icons-provider/icon-provider-platipus';
import { IconProviderGalaxsys } from 'components/icons/icons-provider/icon-provider-galaxsys';
import { IconProviderPoker } from 'components/icons/icons-provider/icon-provider-poker';
import { IconProviderVivo } from 'components/icons/icons-provider/icon-provider-vivo';
import { IconProviderGalaxsysBig } from 'components/icons/icons-provider/icon-provider-galaxsys-big';
import { IconProviderHigh5Big } from 'components/icons/icons-provider/icon-provider-high5-big';
import { IconProviderAvatarUx } from 'components/icons/icons-provider/icon-provider-avatarux';
import { IconProviderAvatarUxBig } from 'components/icons/icons-provider/icon-provider-avatarux-bix';

export const producerIcons = {
  avatarux: IconProviderAvatarUx,
  '1x2-gaming': IconProvider1x2Gaming,
  'betsoft-gaming': IconProviderBetsoft,
  'booming-games': IconProviderBoomingGames,
  booongo: IconProviderBooongo,
  'amusnet-egt': IconProviderAmusnetEgt,
  amusnet: IconProviderAmusnetEgt,
  Amusnet: IconProviderAmusnetEgt,
  evolution: IconProviderEvolution,
  ezugi: IconProviderEzugi,
  gameart: IconProviderGameArt,
  habanero: IconProviderHabanero,
  isoftbet: IconProviderISoftbet,
  microgaming: IconProviderMicroGaming,
  spribe: IconProviderSpribe,
  tvbet: IconProviderTvbet,
  netent: IconProviderNetent,
  playson: IconProviderPlayson,
  pragmatic: IconProviderPragmatic,
  quickspin: IconProviderQuickSpin,
  'red-rake-gaming': IconProviderRedrake,
  'tom-horn': IconProviderTomHornGaming,
  yggdrasil: IconProviderYggdrasil,
  elk: IconProviderElk,
  relax: IconProviderRelax,
  novomatic: IconProviderNovomatic,
  vnet: IconProviderNovomatic,
  'playn-go': IconProviderPlayngo,
  "play'n-go": IconProviderPlayngo,
  playngo: IconProviderPlayngo,
  'nolimit-city': IconProviderNolimit,
  'push-gaming': IconProviderPushgaming,
  hacksaw: IconProviderHacksaw,
  'pragmatic-live': IconProviderPragmaticPlay,
  evoplay: IconProviderEvoplay,
  gamomat: IconProviderGamomat,
  'plank-gaming': IconProviderPlankGaming,
  relaxgaming: IconProviderRelaxGaming,
  'relax-gaming': IconProviderRelaxGaming,
  'big-time-gaming': IconProviderBigtimeGaming,
  oryx: IconProviderOryx,
  kalamba: IconProviderKalamba,
  givme: IconProviderGivme,
  wazdan: IconProviderWazdan,
  endorphina: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/endorphina-small.png" alt="icon-endorphina" />,
  'join-games': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/join-games-small.png" alt="icon-producer-join-games" />,
  spinomenal: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/spinomenal-small.png" alt="icon-producer-spinomenal" />,
  'golden-hero-group': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/golden-hero-small.png" alt="icon-producer-golden-hero-small" />,
  kiron: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/kiron-small.png" alt="icon-producer-kiron-small" />,
  'blueprint-gaming': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/blueprint-gaming-small.png" alt="icon-producer-blueprint-games" />,
  genii: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/genii-small.png" alt="icon-producer-genii" />,
  pgsoft: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg pgsoft', className)} src="/assets/public/producers-icon/pg-soft-small.png" alt="icon-producer-pgsoft" />,
  amatic: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/amatic-small-light.png" alt="icon-producer-amatic-small" />,
  'g-games': IconProviderGGames,
  ggames: IconProviderGGames,
  'games-inc': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/gamesinc-small.png" alt="icon-producer-gamesinc" />,
  'b-gaming': IconProviderBGAMING,
  bgaming: IconProviderBGAMING,
  'betgames-game': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/betgames-game-small.png" alt="icon-producer-betgames-game" />,
  candlebets: IconProviderCandleBets,
  playtech: IconProviderPlaytech,
  'playtech-live': IconProviderPlaytechLive,
  maverick: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/maverick-small.png" alt="icon-producer-maverick" />,
  red7: IconProviderRedSeven,
  'red-tiger': IconProviderRedTiger,
  'retro-gaming': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/retro-gaming-small.png" alt="icon-producer-retro-gaming" />,
  'ruby-play': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/rubyplay-small.png" alt="icon-producer-ruby-play" />,
  swintt: IconProviderSwintt,
  'vivo-gaming': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/vivo-gaming-small.png" alt="icon-producer-vivo-gaming" />,
  vivo: IconProviderVivo,
  reevo: IconProviderReevo,
  'gaming-corps': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/game-corps.png" alt="icon-producer-game-corps" />,
  aviatrix: IconProviderAviatrix,
  '3oaks': IconProvider3oaksGaming,
  fugaso: IconProviderFugaso,
  apollo: IconProviderApollo,
  Apollo: IconProviderApollo,
  Tomhorn: IconProviderTomHornGaming,
  tomhorn: IconProviderTomHornGaming,
  BETGAMES: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/betgames-game-small.png" alt="icon-producer-betgames-game" />,
  betgames: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/betgames-game-small.png" alt="icon-producer-betgames-game" />,
  Betsolutions: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/betsolutions-small.png" alt="icon-producer-betsolutions" />,
  betsolutions: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/betsolutions-small.png" alt="icon-producer-betsolutions" />,
  BETSOLUTIONS: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/betsolutions-small.png" alt="icon-producer-betsolutions" />,
  Egt: IconProviderEgt,
  egt: IconProviderEgt,
  EGT: IconProviderEgt,
  fazi: IconProviderFazi,
  Fazi: IconProviderFazi,
  Gamzix: IconProviderGamzix,
  gamzix: IconProviderGamzix,
  'GT Studio': IconProviderGTStudios,
  'GT studio': IconProviderGTStudios,
  'GT Studios': IconProviderGTStudios,
  'GT studios': IconProviderGTStudios,
  'gt-studio': IconProviderGTStudios,
  'gt-studios': IconProviderGTStudios,
  Elysium: IconProviderElysium,
  elysium: IconProviderElysium,
  Mascot: IconProviderMascot,
  mascot: IconProviderMascot,
  OnlyPlay: IconProviderOnlyPlay,
  Onlyplay: IconProviderOnlyPlay,
  onlyplay: IconProviderOnlyPlay,
  Thunderkick: IconProviderThunderkick,
  thunderkick: IconProviderThunderkick,
  SmartSoft: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/smart-soft.png" alt="icon-producer-smart-soft" />,
  Smartsoft: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/smart-soft.png" alt="icon-producer-smart-soft" />,
  smartsoft: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/smart-soft.png" alt="icon-producer-smart-soft" />,
  'turbo-games': IconProviderTurboGames,
  turbogames: IconProviderTurboGames,
  Turbogames: IconProviderTurboGames,
  TurboGames: IconProviderTurboGames,
  amigogaming: IconProviderAmigoGaming,
  'amigo-gaming': IconProviderAmigoGaming,
  'bf-games': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/bf-games-small.png" alt="icon-producer-bf-games" />,
  Bfgames: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/bf-games-small.png" alt="icon-producer-bf-games" />,
  BFgames: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/bf-games-small.png" alt="icon-producer-bf-games" />,
  BfGames: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/bf-games-small.png" alt="icon-producer-bf-games" />,
  galaxsys: IconProviderGalaxsys,
  platipus: IconProviderPlatipus,
  high5: IconProviderHigh5,
  slotopia: IconProviderSlotopia,

  poker: IconProviderPoker,
  'cash-run': ({ className }) => (
    <img
      className={classNames('producer-category-img producers-icon-not-svg', className)}
      src="/assets/public/producers-icon/cashRun.png"
      alt="icon-producer-cash-run"
    />
  )
};

export const producerIconsBig = {
  avatarux: IconProviderAvatarUxBig,
  '1x2-gaming': IconProvider1x2gamingBig,
  'betsoft-gaming': IconProviderBetsoftBig,
  'booming-games': IconProviderBoomingGamesBig,
  booongo: IconProviderBooongoBig,
  'amusnet-egt': IconProviderAmusnetEgtBig,
  amusnet: IconProviderAmusnetEgtBig,
  Amusnet: IconProviderAmusnetEgtBig,
  evolution: IconProviderEvolutionBig,
  ezugi: IconProviderEzugiBig,
  gameart: IconProviderGameArtBig,
  habanero: IconProviderHabaneroBig,
  isoftbet: IconProviderISoftbetBig,
  microgaming: IconProviderMicrogamingBig,
  spribe: IconProviderSpribeBig,
  tvbet: IconProviderTvbetBig,
  netent: IconProviderNetentBig,
  playson: IconProviderPlaysonBig,
  pragmatic: IconProviderPragmaticBig,
  quickspin: IconProviderQuickSpinBig,
  'red-rake-gaming': IconProviderRedrakeBig,
  'tom-horn': IconProviderTomHornGamingBig,
  yggdrasil: IconProviderYggdrasilBig,
  elk: IconProviderElkBig,
  relax: IconProviderRelaxGamingBig,
  novomatic: IconProviderNovomaticBig,
  vnet: IconProviderNovomaticBig,
  slotopia: IconProviderSlotopia,
  'playn-go': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/playn-go-big.png" alt="icon-producer-playn-go" />,
  "play'n-go": ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/playn-go-big.png" alt="icon-producer-playn-go" />,
  playngo: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/playn-go-big.png" alt="icon-producer-playn-go" />,
  'nolimit-city': IconProviderNolimitBig,
  'push-gaming': IconProviderPushgamingBig,
  hacksaw: IconProviderHacksawBig,
  'pragmatic-live': IconProviderPragmaticLiveBig,
  evoplay: IconProviderEvoplayBig,
  gamomat: IconProviderGamomatBig,
  'plank-gaming': IconProviderPlankGamingBig,
  relaxgaming: IconProviderRelaxGamingBig,
  'relax-gaming': IconProviderRelaxGamingBig,
  'big-time-gaming': IconProviderBigtimeGamingBig,
  oryx: IconProviderOryxBig,
  kalamba: IconProviderKalambaBig,
  givme: IconProviderGivmeBig,
  wazdan: IconProviderWazdanBig,
  galaxsys: IconProviderGalaxsysBig,
  endorphina: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/endorphina.png" alt="icon-endorphina" />,
  'join-games': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/join-games.png" alt="icon-producer-join-games" />,
  spinomenal: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/spinomenal.png" alt="icon-producer-spinomenal" />,
  'golden-hero-group': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/golden-hero.png" alt="icon-producer-golden-hero" />,
  kiron: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/kiron.png" alt="icon-producer-kiron" />,
  'blueprint-gaming': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/blueprint-gaming.png" alt="icon-producer-blueprint-games" />,
  genii: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg genii', className)} src="/assets/public/producers-icon/genii.png" alt="icon-producer-genii" />,
  pgsoft: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg pgsoft', className)} src="/assets/public/producers-icon/pg-soft-big.png" alt="icon-producer-pgsoft" />,
  amatic: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg mx-auto', className)} src="/assets/public/producers-icon/amatic-light.png" alt="icon-producer-amatic" />,
  ggames: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg mx-auto', className)} src="/assets/public/producers-icon/g-games.png" alt="icon-producer-ggames" />,
  'g-games': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg mx-auto', className)} src="/assets/public/producers-icon/g-games.png" alt="icon-producer-ggames" />,
  'games-inc': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/gamesinc.png" alt="icon-producer-gamesinc" />,
  bgaming: IconProviderBGAMINGBig,
  'b-gaming': IconProviderBGAMINGBig,
  'betgames-game': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg producer-category-img producers-icon-not-svg mx-auto', className)} src="/assets/public/producers-icon/betgames-game.png" alt="icon-producer-betgames-game" />,
  candlebets: IconProviderCandleBetsBig,
  playtech: IconProviderPlaytechBig,
  'playtech-live': IconProviderPlaytechLiveBig,
  maverick: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg producer-category-img producers-icon-not-svg mx-auto', className)} src="/assets/public/producers-icon/maverick.png" alt="icon-producer-maverick" />,
  red7: IconProviderRedSevenBig,
  'red-tiger': IconProviderRedTigerBig,
  'retro-gaming': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/retro-gaming.png" alt="icon-producer-retro-gaming" />,
  'ruby-play': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/rubyplay.png" alt="icon-producer-ruby-play" />,
  swintt: IconProviderSwinttBig,
  'vivo-gaming': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/vivo-gaming.png" alt="icon-producer-vivo-gaming" />,
  vivo: IconProviderVivo,
  reevo: IconProviderReevoBig,
  'gaming-corps': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/game-corps-big.png" alt="icon-producer-game-corps" />,
  '3oaks': IconProvider3oaksGamingBig,
  fugaso: IconProviderFugasoBig,
  aviatrix: IconProviderAviatrixBig,
  apollo: IconProviderApolloBig,
  Apollo: IconProviderApolloBig,
  Tomhorn: IconProviderTomHornGamingBig,
  tomhorn: IconProviderTomHornGamingBig,
  BETGAMES: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/betgames-game.png" alt="icon-producer-betgames-game" />,
  betgames: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/betgames-game.png" alt="icon-producer-betgames-game" />,
  Betsolutions: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/betsolutions.png" alt="icon-producer-betsolutions" />,
  betsolutions: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/betsolutions.png" alt="icon-producer-betsolutions" />,
  BETSOLUTIONS: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/betsolutions.png" alt="icon-producer-betsolutions" />,
  Egt: IconProviderEgtBig,
  egt: IconProviderEgtBig,
  EGT: IconProviderEgtBig,
  fazi: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/fazi.png" alt="icon-producer-fazi" />,
  Fazi: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg', className)} src="/assets/public/producers-icon/fazi.png" alt="icon-producer-fazi" />,
  Gamzix: IconProviderGamzixBig,
  gamzix: IconProviderGamzixBig,
  'GT Studio': IconProviderGTStudios,
  'GT studio': IconProviderGTStudios,
  'GT Studios': IconProviderGTStudios,
  'GT studios': IconProviderGTStudios,
  'gt-studio': IconProviderGTStudios,
  'gt-studios': IconProviderGTStudios,
  Elysium: IconProviderElysiumBig,
  elysium: IconProviderElysiumBig,
  Mascot: IconProviderMascotBig,
  mascot: IconProviderMascotBig,
  OnlyPlay: IconProviderOnlyPlayBig,
  Onlyplay: IconProviderOnlyPlayBig,
  onlyplay: IconProviderOnlyPlayBig,
  Thunderkick: IconProviderThunderkickBig,
  thunderkick: IconProviderThunderkickBig,
  SmartSoft: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg w-50 mx-auto mt-0_5', className)} src="/assets/public/producers-icon/smart-soft-big.png" alt="icon-producer-smart-soft" />,
  Smartsoft: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg w-50 mx-auto mt-0_5', className)} src="/assets/public/producers-icon/smart-soft-big.png" alt="icon-producer-smart-soft" />,
  smartsoft: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg w-50 mx-auto mt-0_5', className)} src="/assets/public/producers-icon/smart-soft-big.png" alt="icon-producer-smart-soft" />,
  'bf-games': ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg producer-category-img producers-icon-not-svg mx-auto', className)} src="/assets/public/producers-icon/bf-games.png" alt="icon-producer-bf-games" />,
  Bfgames: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg producer-category-img producers-icon-not-svg mx-auto', className)} src="/assets/public/producers-icon/bf-games.png" alt="icon-producer-bf-games" />,
  BFgames: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg producer-category-img producers-icon-not-svg mx-auto', className)} src="/assets/public/producers-icon/bf-games.png" alt="icon-producer-bf-games" />,
  BfGames: ({ className }) => <img className={classNames('producer-category-img producers-icon-not-svg producer-category-img producers-icon-not-svg mx-auto', className)} src="/assets/public/producers-icon/bf-games.png" alt="icon-producer-bf-games" />,
  'turbo-games': IconProviderTurboGamesBig,
  turbogames: IconProviderTurboGamesBig,
  Turbogames: IconProviderTurboGamesBig,
  TurboGames: IconProviderTurboGamesBig,
  amigogaming: IconProviderAmigoGamingBig,
  'amigo-gaming': IconProviderAmigoGamingBig,
  poker: IconProviderPoker,
  platipus: IconProviderPlatipus,
  high5: IconProviderHigh5Big,
  'cash-run': ({ className }) => (
    <img
      className={classNames('producer-category-img producers-icon-not-svg', className)}
      src="/assets/public/producers-icon/cashRun.png"
      alt="icon-producer-cash-run"
    />
  )
};

export const UnknownProducerIcon = () => <div className="game-producer-icon-unknown bg-gray-10 d-inline-block rounded-circle flex-shrink-0" />;
